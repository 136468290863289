<template>
  <div>
    <v-card v-if="!fetchingData && isMounted">
      <v-toolbar dense dark color="dark">
        <v-toolbar-title v-if="!fetchingData">
          User journey — {{ $t("furtherParticipations") }} {{ $t("of") }}
          {{ leadName }}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <p>{{ $t("furtherParticipationsInfo") }}</p>
        <v-data-table
          class="elevation-2 mitfit-table"
          :loading="fetchingData"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="leads"
          :items-per-page="10"
          :footer-props="{
            'items-per-page-text': $t('itemsPerPage'),
          }"
        >
          <template v-slot:[`item.created`]="{ item }">
            {{ formatDate(item.created) }}
          </template>

          <template v-slot:[`item.campaign.title`]="{ item }">
            <a :href="`/campaigns/${item.campaign.id}`" target="_blank">{{
              item.campaign.title
            }}</a>
          </template>

          <template v-slot:[`item.fullname`]="{ item }">
            <a @click="openLeadDetail(item, item.campaign)">{{
              item.firstName + " " + item.lastName
            }}</a>
          </template>

          <template v-slot:[`item.order`]="{ item }">
            <div v-html="getOrderData(item)"></div>
          </template>
        </v-data-table>

        <!-- <pre>{{ leads }}</pre> -->
      </v-card-text>
    </v-card>

    <v-dialog
      v-if="!fetchingData"
      v-model="dialog"
      max-width="1024px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>Lead Detail</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <LeadDetail
            v-if="!isOrder"
            :name="rando"
            :leadData="leadData"
            :id="leadId"
            :campaign="campaign"
            :key="componentKey"
            :identityInfo="false"
          />
          <LeadDetailView
            v-else
            :name="rando2"
            :leadData="leadData"
            :leadId="leadId"
            :campaign="campaign"
            :key="componentKey2"
            :identityInfo="false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
export default {
  name: "IdentityHistory",

  components: {
    LeadDetail: () => import("@/components/lead/manage/LeadDetail"),
    LeadDetailView: () => import("@/views/leads/LeadDetailView"),
  },

  props: {
    identityUuid: {
      type: String,
      required: true,
    },
    motherLeadId: {
      type: String,
      required: true,
    },
    leadName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      rando: `LeadDetail${Math.random().toString(36).substring(2, 10)}`,
      rando2: `LeadDetailView${Math.random().toString(36).substring(2, 10)}`,
      isMounted: false,
      dialog: false,
      identity: null,
      leads: null,
      leadData: null,
      isOrder: false,
      leadId: null,
      campaign: null,
      componentKey: 0,
      componentKey2: 0,
      fetchingData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      headers: [
        { text: this.$t("participationDate"), value: "created" },
        {
          text: this.$t("campaign"),
          value: "campaign.title",
          cellClass: "font-weight-bold",
        },
        {
          text: this.$t("fullname"),
          value: "fullname",
          cellClass: "font-weight-bold",
        },

        {
          text: this.$t("order"),
          value: "order",
        },
      ],
    };
  },

  created() {
    this.getData();
  },

  mounted() {
    this.componentKey += 1;
    this.isMounted = true;
  },

  methods: {
    generateRandomString() {
      return (
        Math.random().toString(36).substring(2, 10) +
        Math.random().toString(36).substring(2, 10)
      );
    },

    async getData() {
      // GET /feedbackSets/{feedbackSetId}/results/{campaignId}/{leadId}
      this.fetchingData = true;
      const result = await this.getRequest(
        `identities/${this.identityUuid}/history`
      );

      // loop through result.data.data.leads and remove that entry with id = motherLeadId
      result.data.data.leads = result.data.data.leads.filter(
        (lead) => lead.id !== this.motherLeadId
      );

      this.identity = result.data.data.identity;
      this.leads = result.data.data.leads;
      this.fetchingData = false;
    },

    openLeadDetail(lead, campaign) {
      this.isOrder = lead.order ? true : false;
      this.leadData = lead;
      this.leadId = lead.id;
      this.campaign = campaign;
      this.componentKey += 1;
      this.componentKey2 += 1;
      this.dialog = true;
    },

    getOrderData(item) {
      if (item.order) {
        const priceTotal =
          item.order.priceTotal + " " + item.order.currencyCode;
        const items = item.order.items.map((item) => {
          return item.type === "contract"
            ? "<strong>VERTRAG</strong>"
            : "<strong>PRODUKT</strong>" +
                ": " +
                item.title +
                " (" +
                item.amount +
                ")";
        });

        return `<p>${items.join(
          "<br/>"
        )}<br/>--<br/><strong>Gesamtpreis</strong>: ${priceTotal}</p>`;
      }
      return "-";
    },
  },

  mixins: [manageApi, helpers],
};
</script>
